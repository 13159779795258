:root {
  --sfondo-scuro:black;
  --sfondo-chiaro:white;
  --border-radius-mobile-base:20px;
  --colore-font-chiaro:white;
  --colore-font-nero:black;
  --colore-font-01:#d9d9d9;/*#b3b3b3;*/
  --combinazione-colore-dispari-01:#99ccff;
  --combinazione-colore-pari-01:#80bbff;
  --combinazione-colore-dispari-02: #cce6ff;
  --combinazione-colore-pari-02: #b3d9ff;
  --combinazione-colore-dispari-03:#cccccc;
  --combinazione-colore-pari-03:#d9d9d9;
  --combinazione-colore-dispari-04: #e6f3ff;
  --combinazione-colore-pari-04: #cce6ff;
  --linear-gradient-header:linear-gradient(180deg,#ff9d00,#ff9200,#ff8600,#ff7900,#ff6c00);
  --linear-gradient-footer:linear-gradient(180deg,#70708f,#656581,#5a5a72,#4f4f64,#434356);
  --linear-gradient-chiaro:linear-gradient(180deg,#ffffff,#f7f7f7,#fffbfb,#f1eded,#f3f3fd);
  --font-20240922:system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
}


/*
IMPORTANTE: RIPRISTINARE GESTIONE min-height per diverso da header e footer quando si gestirà la pagina funzionante (non in moldaità Coming soon)
*/

@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    src: url(./static/fonts/Roboto-Bold.cc62934cc6.woff2) format("woff2"),url(./static/fonts/Roboto-Bold.36cace202f.woff) format("woff")
}
@font-face {
  font-family: TwitterChirp;
  src: url(./static/fonts/chirp-regular-web.woff) format("woff2"),url(./static/fonts/chirp-regular-web.woff) format("woff");
  font-weight: 400;
  font-style: 'normal';
  font-display: 'swap';
}

/*
First ensure that all HTML elements have the box-sizing property set to border-box. This makes sure that the padding and border are included in the total width and height of the elements.
*/
* {
  box-sizing: border-box;
}

html {
  width: 100vw;
  height: 100vh;
  font-size: calc(16px + (26 - 16) * ((100vw - 300px) / (1600 - 300)));
  scroll-behavior: smooth;
}
/*
MOBILE FIRST 
https://demos.jquerymobile.com/1.4.5/rwd/
https://www.w3schools.com/css/css_rwd_intro.asp
*/
body {
  margin: 0;
  overflow-x: visible;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-shadow: 0 1px 0 #f3f3f3;

  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* inizio plagio da bootsrap*/
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

h1 {
  font-family: 'Roboto Slab',Georgia, serif;
  font-size: 2.5rem;
}

h2 {
  font-family: 'Roboto Slab',Georgia, serif;
  font-size: 2rem;
}


h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}


h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}
p{
    font-size:1rem;
}
@media only screen and (max-width: 600px) {
  p{
      font-size:1.05rem;
  }
}
@media only screen and (min-width: 1200px) {
  p{
      font-size:0.9rem;
  }
}
/* fine  plagio da bootstrap*/

.contenitoreGenerale{
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  position: absolute;
  border: 0;
  padding:0;
  margin:0;
}

.cntCaricamento{
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  justify-content: center;
}
@keyframes ruotaSpinner {
  50% {transform: rotate(360deg);}
}
.spinnerCaricamento{
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top:50%;
  animation: ruotaSpinner 3s infinite;
}

header{
    background-image: var(--linear-gradient-header);
    height:105px;
    border-width: 1px 0;
    position: relative;
    display: flex;
}

header > svg, header > .headerLogoPng{
  height:104px;
  width: inherit;
  box-shadow: none;
  text-shadow: 0 0 transparent;
  margin-left: auto;
  margin-right:auto;
}

.corpoCentrale{
    background-image: url('./static/images/sfondoMobile.jpg');
    min-height: calc(100vh*0.7);
    border-width: 0;
    overflow: visible;
    overflow-x: hidden;
    padding-top: 0;
    padding-right: 1em;
    padding-bottom: 0;
    padding-left: 1em;
}

.rimuoviPadding {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.sezioneTematicaHome{
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  height:auto;
  text-shadow: 0 0 transparent;
  color:var(--colore-font-chiaro);
  border-bottom: 0.1rem solid white;
}

#idHome > .sezioneTematicaHome:nth-child(odd) {
  background-color:transparent;
}

#idHome > .sezioneTematicaHome:nth-child(even) {
  background-color:transparent;
}

#idHome > .sezioneTematicaHome:last-child {
  border-bottom: none;
}

#idNotizie .cntSezione .cntNotizia:nth-child(odd), #idArticoli .cntSezione .previewArticolo:nth-child(odd) , #idOltre .previewArticolo:nth-child(odd){
  background-color: var(--combinazione-colore-dispari-03);
}

#idNotizie .cntSezione .cntNotizia:nth-child(even), #idArticoli .cntSezione .previewArticolo:nth-child(even), #idOltre .previewArticolo:nth-child(even){
  background-color: var(--combinazione-colore-pari-03);
}

#idArticoli .cntSezione .previewArticolo, #idOltre .cntSezione .previewArticolo{
  width:100%;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
}

#idHome>.sezioneTematicaHome *:last-child *:last-child , #idArticoli .previewArticolo{
  margin-bottom:0px;
}

.sezioneTematicaHome p{
  font-size: 1.5em;
}

.sezioneTematicaHome h2{
  font-size: 3em;
}

.sezioneTematicaHome > .sezioneTematicaHomeTitolo , .sezioneTematicaHome > .sezioneTematicaHomeHashtag  {
  text-align: center;
}

.sezioneTematicaHome > .sezioneTematicaHomeTitolo  {
  text-transform: uppercase;
}


.sezioneTematicaHome > .sezioneTematicaHomeTitolo > h2  {
  margin-bottom: 0px;
}


.sezioneTematicaHome > .sezioneTematicaHomeTesto  {
  font-family: 'Roboto Mono',monospace;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.sezioneTematicaHome > .sezioneTematicaHomeTesto > p  {
  margin-top:0px;
  margin-bottom:0px;
}

.sezioneTematicaHome > .sezioneTematicaHomeHashtag  {
  text-transform: capitalize;
}

.sezioneTematicaHome > .sezioneSocial  {
  text-align: center;
}

.corpoCentrale.rimuoviPadding .cntContenutoSeparato{
  margin-top:1em;
  margin-bottom:1em;
  margin-left: auto;
  margin-right:auto;
  width:90%;
  height:auto;
  text-align: center;
}


footer{
    min-height: calc(100vh*0.5);/*questo serve per evitare che ci siano porzioni di pagina vuote pippo*/
    background-image:var(--linear-gradient-footer);
    border-width: 1px 0;
    border-style: solid;
    position: relative;
}

footer div.sezioneFooter {
  text-align: center;
  width:100%;
}

footer div.sezioneFooter h3, footer div.sezioneFooter h4, footer div.sezioneFooter h5, footer div.sezioneFooter h6{
  /* font-family: TwitterChirp; */
  /* font-family:"Open Sans", sans-serif; */
  font-family:'Roboto Mono',monospace,monospace;
  color:white;
  font-weight: bold;
  text-shadow: 0px 0px transparent;
  margin-bottom:0px;
}

footer div.sezioneFooter p{
  font-family: TwitterChirp;
  color:white;
  text-shadow: 0px 0px transparent;
  margin-top:0px;
  margin-bottom:0px;
}

.radiusTestoSezione{
  /*abbinare border e box-shadow se serve*/
  border-radius: var(--border-radius-mobile-base); /* Making border radius */
  width: auto; /* Making auto-sizable width */
  height: auto; /* Making auto-sizable height */
}
/*questo colore di carattere per titoli sta bene con lo sfondo: black, darkblue, darkslateblue, ma anche white
  in caso di sfondo non white, impostare il colore dei caratteri del sottotitolo col white 
*/
.titoloSezione {
    color: #0055fd;
    font-family: Roboto,sans-serif;
    font-size: 48px;
    letter-spacing: -.8px;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    margin-bottom: 13px;
    text-shadow: 0px 0px #007aff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background-color: var(--sfondo-scuro);
    /**/
    display: block;
    margin-block-start: 0.2em;
    margin-block-end: 0.4em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.cntSezione a , .cntSezione a:link, .cntSezione a:visited,
.cntSezione a:hover,.cntSezione a:focus,.cntSezione a:active {
    text-decoration: none;
    color:#0055fd;
    cursor:unset;
}

/*inizio contenuto dinamico*/
.cntContenutoSeparato{
  background-color: rgb(153, 204, 255);
  /* min-height:calc(100vh*0.65); */
  overflow: hidden;
  text-align:center;
  margin-top:1em;
  margin-bottom:1em;
  color:black;
  text-shadow: 0 0 transparent;
  font-family:'Roboto Mono',monospace,monospace;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /*fisso l'altezza per evitare scossoni*/
  height:calc(100vh*0.3);
}
.cntContenutoSeparato .headerContenutoSeparato {
  margin-left:auto;
  margin-right:auto;
}

.cntContenutoSeparato h2{
  text-transform: uppercase;
}
.cntContenutoSeparato h2.sezione{
  font-family: Roboto,sans-serif;
  margin-bottom:0px;
}
.cntContenutoSeparato div {
  text-align:center;
}

.cntContenutoSeparato div p{
  font-family: 'Roboto Mono',monospace;
  margin-top:0px;
  font-weight: bold;
  width:80%;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
}

.cntContenutoSeparato .sezioneHashtagDinamico h4{
  font-family: Roboto,sans-serif;
  text-align: center;
  text-transform: uppercase;
}



/* .contenutoSeparato{
  animation: fadeCompleto 4s infinite;
  -webkit-animation: fadeCompleto 4s infinite;
  -moz-animation: fadeCompleto 4s infinite;
  -o-animation: fadeCompleto 4s infinite;
  -ms-animation: fadfadeCompleto 4s infinite;

} */

.cntContenutoSeparato svg{
  height:calc(100vh*0.2);
  margin-left:auto;
  margin-right:auto;
}
/*fine contenuto dinamico*/

/*preview articolo*/
.previewArticolo{
  margin-top:0;
  margin-left:auto;
  margin-right:auto;
  font-family:'Roboto Mono',monospace,monospace;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align:center;
  text-shadow: 0 0 var(--colore-font-01);
  /* commento le impostazioni non più necessarie */
  /*
  margin-bottom:1em;
  padding-left: 0.5em;
  border-radius:20px;
  border:1px solid var(--colore-font-01);
  */
}

.previewArticolo:hover{
  background-color: #002266;
}

.previewArticolo .sezioneHashtag{
  color:#29292b;
  font-size: 1.2rem;
  /* commento pe proprietà obsolete
    color:#70708f;
    font-size: 0.8rem;
  */
}

.previewArticolo .sezioneHashtag p{
  text-align:center;
  font-size:1em;
  font-weight: bold;

  width:fit-content; 
  width:-webkit-fit-content; 
  width:-moz-fit-content;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
  background-image: var(--linear-gradient-chiaro);
}

.previewArticolo .sezioneImmagineAnteprima img{
  width:100%;
}

.previewArticolo h3, .previewArticolo h2{
  font-family: 'Roboto Slab',Georgia, serif;
  overflow-wrap: break-word;
  margin:auto;
  text-align:center;
  margin-top:0px;
  margin-bottom:0px;
  margin-left:auto;
  margin-right:auto;
  font-size:2.2em;
  /* commento le proprietà vecchie
  color:var(--colore-font-chiaro);
  */
}

.previewArticolo p{
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  overflow-wrap: break-word;
  margin-top:0px;
  margin-bottom:0px;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
  font-size:1.5em;
  /* commento le proprietà vecchie
  margin-top:2px;
  margin-bottom:2px;
  color:var(--colore-font-01);
  text-align:left;
  font-style: oblique;
  */
}

.cntNotizia{
  margin-top:0;
  margin-bottom:0;
  margin-left:auto;
  margin-right:auto;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding-left: 0.5em;
  text-shadow: none;
  /* commento i parametri relativi alla visualizzazione vecchia */
  /* line-height: 1.5; */
  /* margin-bottom:1em; */
  /* border:1px solid var(--colore-font-01); */
  /* border-radius:20px; */
  /* color:black; */
  /* background-color:#ffe6cc; */
}

.cntNotizia h3{
  font-size: 2.8em;
  margin-top:0px;margin-bottom:0px;
  margin-left:auto;margin-right:auto;
  font-family: 'Roboto Slab', 'Times New Roman', Times, serif;
  text-align:center;
}

.cntNotizia p{
  font-size: 1.6em;
  margin-top:0px;margin-bottom:0px;
  margin-left:auto;margin-right:auto;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  text-align:left;
}

.corpoCentrale .cntSezione h1.titoloSezione{
  font-size:3em;
}

.sezioneSocial{
  margin-left:auto;
  margin-right:auto;
}
.sezioneSocial img , .sezioneEmail img {
  margin-right:1em;
  background-color: white;
  height: 40px;
}
.sezioneSmile img , .sezioneRaise img {
  height: 42px;
  cursor: url;
  background-color: white;
}
.sezioneHome img{
  width : 47px;
  height: 42px;
}

.iconaFrecciaSu{
  position: fixed;
  top:calc(100vh*0.9);
  margin-left:calc(100vw*0.78);
  background-color: black;
}
.iconaHome{
  position: fixed;
  top:calc(100vh*0.9);
  margin-left:calc(100vw*0.015);
}
.iconaFrecciaSu img , .iconaHome img{
  width : 42px;
  height: 42px;
}

/* MEDIA QUERY E DINAMICI*/
/*override basato su css nativi di jquery Mobile: */
/*
@media screen and (orientation: portrait) {
	.ui-mobile .ui-page {min-height: 420px;}
}
@media screen and (orientation: landscape) {
    .ui-mobile .ui-page {min-height: 300px;}
}
*/

@media screen and (orientation: portrait) {
	footer{
    min-height: calc(100vh*0.5);/*questo serve per evitare che ci siano porzioni di pagina vuote*/
  }
}

/* da W3Schools*/
/* Extra small devices (phones, 600px and down) */
/* questo lo commento, gli stili sopra sono definiti per questa situazione*/
@media only screen and (max-width: 600px) {
  /*da valutare se gestire portrait per schermi piccoli qui*/
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .previewArticolo .sezioneImmagineAnteprima img{
    width:70%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .corpoCentrale{
    background-image: url('./static/images/sfondo_cool_01.jpg');
  }

  #idHome > .sezioneTematicaHome:nth-child(odd) {
    background-color:var(--combinazione-colore-dispari-04);
  }
  
  #idHome > .sezioneTematicaHome:nth-child(even) {
    background-color:var(--combinazione-colore-pari-04);
  }

  .sezioneTematicaHome{
    color:var(--colore-font-nero);
    border-bottom: none;
  }

  .cntContenutoSeparato{
    width:80%;
    margin-left:auto;
    margin-right:auto;
  }
  .previewArticolo .sezioneImmagineAnteprima img{
    width:60%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  header{
    height:160.5px;
  }
  header > svg, header > .headerLogoPng{
    height:130px;
  }

  #idNotizie .cntSezione .titoloSezione {
    width: 100%;
  }

  /*#idNotizie .cntSezione .cntNotizia:nth-child(odd),#idArticoli .cntSezione .previewArticolo:nth-child(odd) , #idOltre .previewArticolo:nth-child(odd){}*/
  /*#idNotizie .cntSezione .cntNotizia:nth-child(even), #idArticoli .cntSezione .previewArticolo:nth-child(even), #idOltre .previewArticolo:nth-child(even){}*/

  #idNotizie .cntSezione {
    background-image: url('./static/images/sfondoMobile.jpg');
  }

  #idNotizie .cntSezione .titoloSezione{
    background-image: url('./static/images/sfondo_cool_01.jpg');
  }

  #idNotizie .cntSezione .titoloSezione.radiusTestoSezione{
    border-radius: 0;
  }

  #idNotizie .cntSezione .cntNotizia:nth-child(odd), #idNotizie .cntSezione .cntNotizia:nth-child(even) {
    background-color: var(--sfondo-chiaro);
    width: 90%;
    margin-left: 05%;
    margin-right: 05%;
  }

  .cntNotizia h3, .cntNotizia p {
    font-family: var(--font-20240922);
  }

  .cntNotizia h3 {
    font-size: 2rem;
  }

  .cntNotizia p {
    font-size: 0.9rem;
  }

  .previewArticolo .sezioneImmagineAnteprima img{
    width:50%;
  }
  .cntContenutoSeparato{
    width:60%;
    margin-left:auto;
    margin-right:auto;
  }
  footer div.sezioneFooter {
    width:30%;
    display:inline-block;
    margin-left:1em;
    margin-right:auto;
    vertical-align: top;
    border-bottom: transparent;
  }

  .iconaFrecciaSu{
    top:calc(100vh*0.8);
    margin-left:calc(100vw*0.015);
  }
  .iconaHome{
    top:calc(100vh*0.9);
    margin-left:calc(100vw*0.015);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  header{
    min-height:160.5px;
  }
  header > svg, header > .headerLogoPng{
    min-height:150px;
  }

  #idNotizie .titoloSezione, #idArticoli .titoloSezione, #idOltre .titoloSezione {
    background-color: transparent;
  }
  .cntContenutoSeparato{
    width:60%;
    margin-left:auto;
    margin-right:auto;
  }
  #idHome .cntSezione{
    width:30%;
    display:inline-block;
    margin-left:1em;
    margin-right:auto;
  }
  #idHome .cntSezione .radiusTestoSezione , footer .cntSezione .radiusTestoSezione{
    height:calc(100vh*0.2);
    display: flex;
    justify-content: center;
    align-items: center
  }
  #idHome .cntSezione .radiusTestoSezione:hover , footer .cntSezione .radiusTestoSezione:hover{
    background-color: darkgrey;
  }

  /*#idNotizie .cntSezione .titoloSezione,*/
  #idArticoli .cntSezione .titoloSezione, #idOltre .cntSezione .titoloSezione {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  /*  , #idNotizie .cntNotizia */
  #idArticoli .previewArticolo, #idOltre .previewArticolo{
    width:30%;
    display:inline-block;
    vertical-align: top;
    margin-left:1em;
    margin-right:auto;
  }

  .previewArticolo h3, .previewArticolo h2{
    font-size:2rem;
  }
  
  .previewArticolo p{
    font-size:1.4rem;
  }
  
  /* @media screen and (orientation: landscape) {
    footer {
      min-height:auto;
      height: auto;
    }
  } */
}


/* VARI ED EVENTUALI*/
.sfondoNero{background-color: var(--sfondo-scuro);}
.sfondoChiaro{background-color: var(--sfondo-chiaro);}

/*animazioni*/
/*animazione*/
@keyframes fadeCompleto {
  0% {opacity:0;}
  50% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

@-moz-keyframes fadeCompleto {
  0% {opacity:0;}
  50% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeCompleto {
  0% {opacity:0;}
  50% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeCompleto {
  0% {opacity:0;}
  50% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

@-ms-keyframes fadeCompleto {
  0% {opacity:0;}
  50% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

/* per SVG*/
#idBurger01G970 rect { fill: url(#idBurger01Gradient01); }
.idBurger01Gradient01Stop1 { stop-color: #b2505c; }
.idBurger01Gradient01Stop2 { stop-color: #8f2430;stop-opacity:0.544444; }
.idBurger01Gradient01Stop3 { stop-color: #4c131a; }
/* vari, da integrare*/

